import React, { useState } from "react";
import { Link } from "gatsby";
import { IntlContextConsumer } from "gatsby-plugin-intl";
import { useIntl } from "gatsby-plugin-intl";

import TitledContainer from "../../components/titledContainer";

import { formatDate } from "../../utils/functions";

import "./index.scss";

const numPerPage = 6;

const Presentations = ({data}) => {
  const [page, setPage] = useState(1);
  let pagLen = 1;

  const generatePagination = () => {
    const pagination = [];
    let pgCount = Math.ceil(data.length / numPerPage);

    pagLen = pgCount;
    for (; pgCount > 0; pgCount--) {
      pagination.unshift(pgCount);
    }
    return pagination;
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <TitledContainer
          title={currentLocale === "fr" ? "CLIQUE POUR VOIR" : "CLICK TO VIEW"}
          pagination={generatePagination()}
          page={page}
          setPage={setPage}
          pagLen={pagLen}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 mb-12 gap-5 mt-8">
            {data
              .slice((page - 1) * numPerPage, (page - 1) * numPerPage + numPerPage)
              .map(({ node: { image, title, linkTitle, linkUrl, formattedDate } }) => (
                <Link to={linkUrl} target="_blank" className="event-card-container rounded-xl overflow-hidden">
                  <div className="card-image">
                    <img src={image?.file?.url} alt={title} className="w-full h-full" />
                  </div>
                  <div className="card-detail flex flex-col gap-3 pb-5 pt-8 px-8">
                    <h5 className="text-primary">{title}</h5>
                    <p className="font-xs uppercase">{formatDate(formattedDate, currentLocale)}</p>
                  </div>
                </Link>
              ))}
          </div>
        </TitledContainer>
      )}
    </IntlContextConsumer>
  );
};

export default Presentations;
