import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import HeroWithImage from "../../components/heroWithImage";
import Presentations from "../../containers/Presentations";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import DisclosureBox from "../../components/disclosure-box";

import heroDesktop from "../../assets/heroDesktop/Presentations.png";
import heroMobile from "../../assets/heroMobile/Presentations.png";

const CorporateDirectory = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="Events with presentation" />
      <HeroWithImage
        title={intl.locale === "fr" ? "PRÉSENTATIONS" : "PRESENTATIONS"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      {data.events.edges.length > 0 && <Presentations data={data.events.edges} />}
      <DisclosureBox />
    </Layout>
  );
};

export const query = graphql`
  query EventsAndPresentationQuery($locale: String) {
    events: allContentfulEventsPresentationsEvents(
      filter: { node_locale: { eq: $locale }, hide: { eq: false } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          date(formatString: "MM-DD-YYYY")
          formattedDate: date(formatString: "MMM DD, YYYY")
          linkTitle
          linkUrl
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default CorporateDirectory;
