import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { IntlContextConsumer } from "gatsby-plugin-intl";

import RRenderer from "../richtextRenderer";

const DisclosureBox = () => {
  const { textEn, textFr } = useStaticQuery(graphql`
    query DisclosureBox {
      textEn: allContentfulDisclosureBox(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          disclosureBoxText {
            raw
          }
        }
      }
      textFr: allContentfulDisclosureBox(filter: { node_locale: { eq: "fr" } }) {
        nodes {
          disclosureBoxText {
            raw
          }
        }
      }
    }
  `);

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => {
        if (currentLocale === "fr") {
          return (
            <div className="global-x-spacing px-0 lg:px-16 max-w-78 mx-auto my-20">
              <div className="w-full px-5 md:px-12 py-4 md:py-7.5 bg-dark-gray rounded-2xl">
                <RRenderer
                  data={textFr.nodes[0].disclosureBoxText}
                  config={{
                    p: "py-2",
                  }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="global-x-spacing px-0 lg:px-16 max-w-78 mx-auto my-20">
              <div className="w-full px-5 md:px-12 py-4 md:py-7.5 bg-dark-gray rounded-2xl">
                <RRenderer
                  data={textEn.nodes[0].disclosureBoxText}
                  config={{
                    p: "py-2",
                  }}
                />
              </div>
            </div>
          );
        }
      }}
    </IntlContextConsumer>
  );
};

export default DisclosureBox;
